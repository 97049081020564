<template>
  <div class="home-container">
    <div class="home-header">
      <el-tag style="margin-right: 5px" type="warning"
        >选择对应地址节点，可导入对应节点下的地址信息。默认节点为光明街道</el-tag
      >
      <el-button type="primary" size="small" @click="importData"
        >批量导入</el-button
      >
    </div>
    <div class="filter-css">
      <el-input
        size="small"
        placeholder="输入关键字进行过滤"
        v-model="filterText"
      >
      </el-input>
    </div>
    <el-tree
      ref="tree"
      :data="treeData"
      :props="treeProps"
      highlight-current
      :default-expand-all="false"
      :default-expanded-keys="defaultExpands"
      :current-node-key="currentNodeKey"
      @node-click="nodeClick"
      node-key="addrId"
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
        <span>
          <el-button
            v-if="node.level < 6"
            type="text"
            size="mini"
            @click="() => append(data, 1)"
          >
            新增
          </el-button>
          <el-button type="text" size="mini" @click="() => append(data, 2)">
            编辑
          </el-button>
        </span>
      </span>
    </el-tree>
  </div>
</template>

<script>
import { getAddrList, addrUpload } from "@/api/index";
export default {
  data() {
    return {
      currentNodeKey: "1",
      defaultExpands: [1],
      treeData: [],
      treeProps: {
        label: "addrName",
      },
      filterText: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.getTable();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.addrName.indexOf(value) !== -1;
    },
    importData() {
      this.$utils.importFile(addrUpload, { addrId: this.currentNodeKey }, this);
    },
    nodeClick(data, node) {
      console.log(data, node);
      this.currentNodeKey = data.addrId;
    },
    // 获取地址列表
    getTable() {
      getAddrList().then((res) => {
        if (res.data.code == 200) {
          this.treeData = this.$utils.arrToTree(
            res.data.data,
            "addrId",
            "addrParentId"
          );
          this.treeData[0].children.forEach((item) => {
            this.defaultExpands.push(item.addrId);
          });
        }
      });
    },
    async del(item) {
      this.$confirm("确定删除？", "提示", {
        type: "warning",
      }).then(() => {
        console.log(item);
      });
    },
    async append(row, type) {
      let str = type == 1 ? "新增" : "编辑";
      let str1 = "地址";
      let level = type == 1 ? row.addrLevel + 1 : row.addrLevel;
      switch (level) {
        case 1:
          str1 = "街道";
          break;
        case 2:
          str1 = "居委会";
          break;
        case 3:
          str1 = "小区";
          break;
        case 4:
          str1 = "楼号";
          break;
        case 5:
          str1 = "单元号";
          break;
        case 6:
          str1 = "门牌号";
          break;
        default:
          break;
      }
      let vm = this;
      let vNode = this.$createElement((await import("./post.vue")).default, {
        key: Math.random(),
        props: {
          row: row,
          label: str1,
          type: type,
        },
        on: {
          success() {
            vm.getTable();
          },
        },
      });
      this.$adminDialog(vNode, {
        title: str + str1,
        width: "530px",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-header {
  margin-bottom: 10px;
  padding: 15px;
  background: #fff;
}
.home-container {
  padding: 10px;
}
.filter-css {
  padding: 10px;
  background-color: #fff;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
